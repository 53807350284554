import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pusher from 'pusher-js';
import { setLiveBets } from '../../features/liveBetsSlice';
import { toast } from 'react-toastify';

import {
    Button,
    Card,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Typography
} from "@material-tailwind/react";
import { BanknotesIcon, ClockIcon, CurrencyDollarIcon, DocumentMagnifyingGlassIcon,  TrophyIcon, UserIcon } from '@heroicons/react/24/outline';
import { setJackpot } from '../../features/jackpotSlice';
import axios from 'axios';
import Moment from 'react-moment';

axios.defaults.withCredentials = true;




export default function MelbetJackpot() {
    const PREVIOUS_JACKPOT_WINNERS_TABLE_HEAD = ["","USERNAME", "CASHOUT POINT", "AMOUNT WON", "DATE"];

    const dispatch = useDispatch();
    const jackpot = useSelector((state) => state.jackpot.value);
    const userId = useSelector((state) => state.userId.value);


    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const [websocketsDomain, setWebsocketsDomain] = useState(window.WEBSOCKETS_DOMAIN);
    const [websocketsConnection, setWebsocketsConnection] = useState(window.WEBSOCKETS_CONNECTION);
    const [pusherAppKey, setPusherAppKey] = useState(window.PUSHER_APP_KEY);
    const JACKPOT_TABLE_HEAD = ["POSITION", "USERNAME", "CASHOUT POINT"];

    const [previousJackpotWinners, setPreviousJackpotWinners] = useState([]);
    const [previousJackpotWinnersProcessing, setPreviousJackpotWinnersProcessing] = useState(false);

    const errorNotify = (message) => {
        toast.error(message, {
        });
    }


    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));
    const enableBanner = JSON.parse(localStorage.getItem('enableBanner'));



    const [openPreviousJackpotWinners, setOpenPreviousJackpotWinners] = React.useState(false);
    const handleOpenPreviousJackpotWinners = () => setOpenPreviousJackpotWinners(!openPreviousJackpotWinners);

    const fetchPreviousJackpotWinners = async (e) => {
        try {
            setPreviousJackpotWinnersProcessing(() => true);
            handleOpenPreviousJackpotWinners();

            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/fetchPreviousJackpotWinners`,
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            setPreviousJackpotWinners(() => response?.data);
            setPreviousJackpotWinnersProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            handleOpenPreviousJackpotWinners();
            setPreviousJackpotWinnersProcessing(() => false);
        }
    }




    const formatCoefficient = (coefficient) => {
        if (coefficient !== null) {
            var text1 = "x";
            var num = parseFloat(coefficient).toFixed(2);
            let result = text1.concat(num);
            return result;
        }
        else {
            return coefficient;
        }
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }






    useEffect(() => {

        if (websocketsConnection === 'self_hosted') {
            //self hosted websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 443,
                forceTLS: true,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 443,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else if (websocketsConnection === 'local') {
            //local server websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 6001,
                forceTLS: false,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 6001,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else {
            // paid pusher configuration
            var pusher = new Pusher(pusherAppKey, {
                cluster: 'eu'
            });
        }

        const fetchData = async () => {

            const channel = pusher.subscribe('JackpotChannel');
            channel.bind('JackpotEvent', function (data) {

                //  console.log(data.jackpot);

                dispatch(setJackpot(data.jackpot));


            })

        }
        fetchData();
    }, []);






    return (
        <div className={'h-[320px] pt-0 mt-0 pb-14 '
            + ((enableBanner == false) ? ' md:h-[450px]' : ' md:h-[585px]')}>

            {/* <div className="items-center mt-0 p-0 md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#f7a026] to-[#c83c48] rounded-none  text-xs text-gray-50 w-full">  */}
            {/* <div className="items-center mt-0 p-0 md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#49c92c] to-[#0652dd] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="items-center mt-0 p-0 md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#f7a026] to-[#0652dd] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="items-center mt-0 p-0  md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#FF4500] to-[#6b1d00] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="items-center mt-0 p-0 md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#d25c18] to-[#c83c48] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="items-center mt-0 p-0 md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#f7a026] to-[#49c92c] rounded-none  text-xs text-gray-50 w-full">  */}
            <div className="items-center mt-0 p-0 md:p-2 h-20 md:h-20 bg-gradient-to-r from-[#eb5ccf] to-[#642269] rounded-none  text-xs text-gray-50 w-full">

                <div className='w-full '>
                    <div className="font-bold flex items-center justify-center text-center">
                        <span className='text-[16px]'>Daily Jackpot</span>
                    </div>
                </div>

                <div className='grid grid-cols-5 gap-2 w-full text-xs mt-1'>
                    <div className="font-medium">
                        <span className='flex items-center justify-center text-center'>Minimum Bet</span>
                        <span className='flex flex-cols items-center justify-center gap-1'><CurrencyDollarIcon className="h-3 w-3 text-white" strokeWidth={3.0} />{formatAmount(jackpot.jackpotMinimumBet)}</span>
                    </div>
                    <div className="font-medium">
                        <span className='flex items-center justify-center text-center'>Minimum Cashout</span>
                        <span className='flex flex-cols items-center justify-center gap-1'><UserIcon className="h-3 w-3 text-white" strokeWidth={3.0} />{formatCoefficient(jackpot.jackpotMinimumCashoutPoint)}</span>
                    </div>
                    <div className="font-medium">
                        <span className='flex items-center justify-center text-center'>Reward Time</span>
                        <span className='flex flex-cols items-center justify-center gap-1'><ClockIcon className="h-3 w-3 text-white" strokeWidth={3.0} />11:00 PM</span>
                    </div>
                    <div className="font-medium">
                        <span className='flex items-center justify-center text-center'>Reward Amount</span>
                        <span className='flex flex-cols items-center justify-center gap-1'><BanknotesIcon className="h-3 w-3 text-white" strokeWidth={3.0} />{formatAmount(jackpot.jackpotAmount)}</span>
                    </div>
                    <div className="font-medium">
                        <span className='flex flex-cols items-center justify-center gap-1'>
                            <button onClick={fetchPreviousJackpotWinners} className="flex justify-start top-0 mx-0.5  px-2 py-0.5 text-gray-100 rounded-md bg-none border  bg-[#272b4d] border-[#1f1e43] hover:bg-[#111111] hover:border-[#111111]  active:border-0 transition duration-500 ease-in-out ">Previous Winners</button>
                        </span>
                    </div>
                </div>
            </div>
            <Card className="p-1 md:p-0 w-full h-full rounded-none bg-[#181c3a]  overflow-y-scroll">

                <table className="w-full min-w-max table-auto text-left bg-[#181c3a]">
                    <thead>
                        <tr>
                            {JACKPOT_TABLE_HEAD.map((head) => (
                                <th key={head} className="border-b bg-[#181c3a] p-2 text-gray-400">
                                    <Typography
                                        variant="small"
                                        color="inherit"
                                        className="text-xs leading-none font-medium"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {jackpot.jackpotEntries.map(({ id, user_id, username, cashout }, index) => {
                            const classes = "px-1 py-1 bg-[#181c3a] border-b-2 border-[#2e335c] ";

                            return (

                                <tr key={id} className={"rounded-md " + ((user_id == userId) ? " text-[#8fc92c]" : " text-gray-400")}>
                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium ">
                                            {+index + 1}
                                        </Typography>

                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium ">
                                            {username}
                                        </Typography>

                                    </td>

                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                            {formatCoefficient(cashout)}
                                        </Typography>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>

            </Card>



            <Dialog
                open={openPreviousJackpotWinners}
                handler={handleOpenPreviousJackpotWinners}

                size={"xs"}
                className="bg-[#181c3a]"
            >
                <DialogHeader className="text-gray-300">

                    <span>Daily Jackpot Winners</span>

                </DialogHeader>


                <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">

                    {(previousJackpotWinnersProcessing === true) &&
                        <Button ripple={true} size="lg" color="blue" className="w-full">
                            <span className="flex gap-2 items-center w-full justify-center">
                                <svg className="animate-spin h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Loading...
                            </span>
                        </Button>
                    }

                    {(previousJackpotWinnersProcessing === false) &&

                        <Card className="w-full h-full rounded-none bg-[#181c3a] ">

                            <table className="w-full min-w-max table-auto text-left bg-[#181c3a]">
                                <thead>
                                    <tr>
                                        {PREVIOUS_JACKPOT_WINNERS_TABLE_HEAD.map((head) => (
                                            <th key={head} className="border-b bg-[#181c3a] p-2 text-gray-400">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                {(previousJackpotWinners !== undefined || previousJackpotWinners.length !== 0) &&
                                    <tbody>
                                        {previousJackpotWinners.map(({ id, username, cashout, amount, created_at, }, index) => {
                                            const classes = "px-1 py-1 bg-[#181c3a] border-b-2 border-[#2e335c]";

                                            return (
                                                <tr key={id} className="rounded-md text-gray-400" >
                                                    <td>
                                                    <span><TrophyIcon className="h-4 w-4 text-white" strokeWidth={2.0} /></span>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                            {username}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                            {formatCoefficient(cashout)}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                            {formatAmount(amount)}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="inherit" className="text-xs font-medium ">
                                                            <Moment format="YYYY-MMM-DD h:mm a">
                                                                {created_at}
                                                            </Moment>
                                                        </Typography>

                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                }
                            </table>

                            {(previousJackpotWinners === undefined || previousJackpotWinners.length == 0) &&
                                <div className="flex justify-center items-center h-full flex-col my-4">
                                    <DocumentMagnifyingGlassIcon className="h-24 w-24 text-[#a3a4b0] col-span-full" strokeWidth={2.0} />
                                    <span className="text-[#a3a4b0] col-span-full text-xl">There are no previous jackpot winners yet</span>
                                </div>
                            }
                        </Card>
                    }


                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpenPreviousJackpotWinners}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                </DialogFooter>
            </Dialog>




        </div>
    )
}
